import React, { useRef, useEffect, useState } from 'react';

const AudioPlayer = ({ data, isMuted }) => {
  const audioRef = useRef(null);
  const [audioStarted, setAudioStarted] = useState(false);

  const playAudio = () => {
    const currentAudio = audioRef.current;
    if (currentAudio && !audioStarted) {
      currentAudio.play().catch((error) => {
        console.error('Erro ao reproduzir o áudio automaticamente:', error);
      });
      setAudioStarted(true);
    }
  };

  useEffect(() => {
    const currentAudio = audioRef.current;

    if (currentAudio) {
      currentAudio.muted = isMuted; // Define o estado de mute/unmute
      currentAudio.volume = data.volume || 0.5;
      if (isMuted) {
        currentAudio.pause(); // Pausa o áudio quando mutado
      } else if (audioStarted) {
        currentAudio.play().catch((error) => {
          console.error('Erro ao reproduzir o áudio:', error);
        });
      }
    }
  }, [isMuted, audioStarted]);

  useEffect(() => {
    const handleFirstInteraction = () => {
      playAudio(); // Toca o áudio na primeira interação
      window.removeEventListener('click', handleFirstInteraction); // Remove o listener após a primeira interação
    };

    window.addEventListener('click', handleFirstInteraction);

    return () => {
      window.removeEventListener('click', handleFirstInteraction);
    };
  }, []);

  return (
    <audio ref={audioRef} loop>
      <source src={data.src} type="audio/mpeg" />
      <source src={data.src.replace('.mp3', '.ogg')} type="audio/ogg" />
      Seu navegador não suporta o elemento de áudio.
    </audio>
  );
};

export default AudioPlayer;
