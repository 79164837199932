  import Medusa from "@medusajs/medusa-js"
import Cookies from "js-cookie"

//const Token = Cookies.get('access_token')
//const Token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoidXNyXzAxSFRaQThROEQ2UlFQUjlKU04wRFg4R1hKIiwiZG9tYWluIjoiYWRtaW4iLCJpYXQiOjE3MTc2OTU2MTUsImV4cCI6MTcxNzc4MjAxNX0.RHCMxTk301HK1PiUri5aTuphyDEsSHhMFmJmILl0UqQ";
const Token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoidXNyXzAxSFRaQThROEQ2UlFQUjlKU04wRFg4R1hKIiwiZG9tYWluIjoiYWRtaW4iLCJpYXQiOjE3MTgxMTYyNDksImV4cCI6MTcxODIwMjY0OX0.HTtrSI1Z_kSjbtFlbjyWwWX7J_zrsrmIeLjDJqNrjFU";

  const clientMedusa = new Medusa({
    maxRetries: 3,
    baseUrl: process.env.REACT_APP_MEDUSA_API_URL,
    customHeaders: {
      "x-publishable-api-key": process.env.REACT_APP_MEDUSA_API_KEY,
      "Authorization": "Bearer " + Token
    }
  })


  export default clientMedusa
// export default clientMedusa
