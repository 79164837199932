import { React, useContext } from 'react';
import btnView360 from "./images/btnShow360.png";
import DataContext from '../context/context.js';

const Button360 = (props) => {
    const handlePostMessage = useContext(DataContext);
  const handleClick = async () => {
      const message = {
          type: "requestView3dUrl",
          id: props.id,
      };
      handlePostMessage(message);
    };

  return (
    <img
      className="absolute bottom-1 right-1 cursor-pointer"
      style={{
        height: "55px",
        width: "auto"
      }}
      src={btnView360}
      onClick={handleClick}
      alt="View 360"
    />
  );
};

export { Button360 };
