import listProduct from "./functions/listProduct";
import clientMedusa from "./auth";

export default class MedusaRequest{
  fetchProducts() {
    return clientMedusa.products.list().then((products) => {
      return products?.products.map(p => listProduct(p) )
    })
  }
}
