// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fullscreen-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    height: 46px;
    width: 50px;
    background-color: #fff;
    color: #000;
    border: 1px solid #CCCCCC; /* Borda cinza escuro */
    padding: 10px 10px;
    cursor: pointer;
    border-radius: 8px;
    z-index: 10;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  }
  
  .fullscreen-button:hover {
    background-color: #f0f0f0;
    border-color: #555; /* Bordas mais escuras ao passar o mouse */
  }
  
  .fullscreen-icon {
    width: 100%;  /* Ajusta a largura do ícone */
    height: 100%; /* Ajusta a altura do ícone */
    object-fit: contain; /* Garante que o ícone não se distorça */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/controls/fullscreen/assets/fullscreen.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,WAAW;IACX,sBAAsB;IACtB,WAAW;IACX,yBAAyB,EAAE,uBAAuB;IAClD,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,+EAA+E;EACjF;;EAEA;IACE,yBAAyB;IACzB,kBAAkB,EAAE,0CAA0C;EAChE;;EAEA;IACE,WAAW,GAAG,8BAA8B;IAC5C,YAAY,EAAE,6BAA6B;IAC3C,mBAAmB,EAAE,wCAAwC;EAC/D","sourcesContent":[".fullscreen-button {\n    position: fixed;\n    bottom: 20px;\n    right: 20px;\n    height: 46px;\n    width: 50px;\n    background-color: #fff;\n    color: #000;\n    border: 1px solid #CCCCCC; /* Borda cinza escuro */\n    padding: 10px 10px;\n    cursor: pointer;\n    border-radius: 8px;\n    z-index: 10;\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;\n  }\n  \n  .fullscreen-button:hover {\n    background-color: #f0f0f0;\n    border-color: #555; /* Bordas mais escuras ao passar o mouse */\n  }\n  \n  .fullscreen-icon {\n    width: 100%;  /* Ajusta a largura do ícone */\n    height: 100%; /* Ajusta a altura do ícone */\n    object-fit: contain; /* Garante que o ícone não se distorça */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
