const BabylonIframe = (props) => {
  const { galleryData } = props;
  const baseUrl = process.env.REACT_APP_ROOT; // Fallback URL
  if(galleryData == null) return;
  const srcUrl = baseUrl+galleryData.path;
  return (
    <iframe
      className="iframeBabylon"
      title="Babylon Viewer"
      src={srcUrl}
      width="100%"
      height="100%"
      frameBorder="0"
      style={{ position: "absolute", top: 0, left: 0 }}
      allow="xr-spatial-tracking"
    ></iframe>
  );
};

export { BabylonIframe };
