import React, { useEffect, useState, useRef } from 'react';
import './assets/css/door.css';
import doorLeft from "./assets/images/left.png";
import doorRight from "./assets/images/right.png";
import introtest from "../instructions/assets/videos/introtest.webm";

const Door = (props) => {
  const { setDoor, setIsLoading } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const videoRef = useRef(null);

  const toggleDoor = () => {
    setIsOpen(true);
    setIsLoading(true);
    setTimeout(() => {
      setDoor(false);
    }, 3000);
  };

  useEffect(() => {
    const checkIsMobile = () => window.innerWidth <= 1200;
    setIsMobile(checkIsMobile());

    const handleResize = () => setIsMobile(checkIsMobile());
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener("ended", handleVideoEnd);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("ended", handleVideoEnd);
      }
    };
  }, []);

  const handleVideoEnd = () => {
    setTimeout(() => {
      setIsLoading(false);
      setDoor(false);
    }, 1500);
  };

  const wisdomiaPath = "/wisdomia";
  const currentPath = window.location.pathname;
  const isWisdomiaPath = currentPath === wisdomiaPath;

  return (
    <div className={`porta ${isOpen ? 'aberta' : ''}`}>
      {isWisdomiaPath && !isMobile ? (
        <video
          ref={videoRef}
          className="intro-video"
          autoPlay
          muted
          onEnded={handleVideoEnd}
          style={{ width: '100%', height: '100%' }}
        >
          <source src={introtest} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <>
          <div className="parte-esquerda bg-slate-700" onClick={toggleDoor}>
            <img className='sideImage' src={doorLeft} alt="Left Side Door" />
          </div>
          <div className="parte-direita bg-slate-700" onClick={toggleDoor}>
            <img className='sideImage' src={doorRight} alt="Right Side Door" />
          </div>
        </>
      )}
    </div>
  );
};

export { Door };
