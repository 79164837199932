import { useState, useEffect } from "react";
import DataContext from "./components/context/context.js";
import AudioPlayer from "./components/audio/audioPlayer.js";
//import WebViewModal from "./components/webview/webviewModal.js";
import { WebViewModal } from "./components/webview/webviewModel.js";
import { ListProduts } from "./components/pictures/ListProduts.js";
import { ProductDetail } from "./components/ProductDetail/ProductDetail.js";
import { BabylonIframe } from "./components/babylon/BabylonIframe.js";
import { ListActions } from "./components/controls/ListActions.js";
import { Instructions } from "./components/instructions/Instructions.js";
import { Loader } from "./components/load/loader.js";
import { Door } from "./components/door/door.js";
import { Viewer3d } from "./components/viewer3d/viewer3d.js";
import { ElevatorOptions } from "./components/elevator/elevator.js";
import { Fullscreen } from "./components/controls/fullscreen/fullscreen.js";
import "./App.css";
import "./index.css";
import APIRequest from "./services/apiRequest.js";
import { provider } from "./services/request.js";
import { MedusaProvider } from "medusa-react";
import { QueryClient } from "@tanstack/react-query";

import { useProducts, useAdminStore } from "medusa-react";
import { useAdminCustomQuery } from "medusa-react"
import { TeleporterOptions } from "./components/teleporter/teleporterOptions.js";

const galleriesData = require('../src/assets/json/galleries.json');

const handlePostMessage = (message) => {
  const iframe = document.querySelector("iframe");
  if (iframe) {
    
    iframe.contentWindow.postMessage(message, "*");
  }
};

function Gallery(props) {
  let { changeGallery } = props;
  const [data, setData] = useState([]);
  const [selectedPicture, setSelectedPicture] = useState(null);
  const [listVisible, setListVisible] = useState(false);
  const [muteAudio, setMuteAudio] = useState(false);

  const [galleryData, setGalleryData] = useState(null);
  const [mannequinButton, SetMannequinButton, handleButton] = useState();

  const [showWebView, setShowWebView] = useState(false); // Estado para controlar a exibição do WebView
  const [url, setUrl] = useState("");

  const handleWebView = (url) => {
    setUrl(url);
    setShowWebView(true);
  }; 
  const closeWebView = () => {
    setShowWebView(false);
    setUrl(""); // Limpa a URL ao fechar
  };

  const handleArrowClick = (direction) => {
    const iframe = document.querySelector("iframe");
    if (iframe) {
      const message = {
        type: "arrowClick",
        direction: direction,
      };
      iframe.contentWindow.postMessage(message, "*");
    }
  };

  const { products, isLoading } = useProducts()
  const { store, isLoading: isLoadingStore } = useAdminStore()

  const handleOptionTeleportSelect = (option) => {
    const message = {
      type: "teleporter",
      option: option,
    };
    handlePostMessage(message);
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === "viewDetail") {
        const id = event.data.data.id;

        if (data && data.pictures) {
          const foundObject = data.pictures.find((picture) => picture._id === id);
          setSelectedPicture(foundObject);
          setListVisible(true);
        }
      }

      if (event.data.type === "objectSelected") {
        const objectId = event.data.objectInfo.id;

        if (data && data.pictures) {
          const foundObject = data.pictures.find((picture) => picture._id === objectId);
          setSelectedPicture(foundObject);
          setListVisible(true);
        }
      }


    };

    const fetchData = async () => {
      const products = await provider("medusa").fetchProducts()
      //console.log(products); 
      //const template_id = await APIRequest.fetchStoreTemplate();
      const stores = await APIRequest.fetchStores();
      //console.log("stores: ", stores); 
      //const products = await APIRequest.fetchProducts();
      // debugger
      /*
      */
    };

    // fetchData();

    const handleButton = (event) => {
      if (event.data.type === "mannequinButton") {
        const object = event.data.objectInfo.status;
        SetMannequinButton(object);
      }
    };

    window.addEventListener("message", handleButton);
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleButton);
      window.removeEventListener("message", handleMessage);
    };
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      if (galleryData == null) return;
      try {
        const url = process.env.REACT_APP_GALLERY_API;
        const response = await fetch(`${url + galleryData.id}`);
        const gallery = await response.json();
        setData(gallery);
      } catch (error) {
        console.log("Error getting store data: ", error);
      }
    };

    fetchData();
  }, [galleryData]);

  useEffect(() => {
    const route = window.location.pathname.substring(1);
    const currentGalleryData = galleriesData.find(g => g.route === route);
    if (currentGalleryData) {
      setGalleryData(currentGalleryData);
      setDocumentTitle(currentGalleryData.title);
    } else {
      setGalleryData(galleriesData[0]); // Ou uma galeria default específica
    }
  }, [window.location.pathname]);

  const setDocumentTitle = (title) => {
    if (title != null)
      document.title = title;
  };
  const handleDestroyGallery = () => {
    const iframe = document.querySelector("iframe");
    if (iframe) {
      const message = {
        type: "executeFunction",
        functionName: "DestroyScene",
      };
      iframe.contentWindow.postMessage(message, "*");
    }
  };

  const setNewGallery = (option) => {
    setSelectedPicture(null);
    changeGallery();
    setGalleryData(option);
    handleDestroyGallery();
  };

  const executeFunctionWithId = (id) => {
    const iframe = document.querySelector("iframe");
    if (iframe) {
      const message = {
        type: "executeFunction",
        functionName: "findObjectById",
        arguments: [id],
      };
      iframe.contentWindow.postMessage(message, "*");
    }
  };

  const executeAudioFunction = (setMuted) => {
    const iframe = document.querySelector("iframe");
    if (iframe) {
      const message = {
        type: "executeFunction",
        functionName: "setAudioState",
        arguments: [setMuted],
      };
      iframe.contentWindow.postMessage(message, "*");
    }
  };

  const handlePictureClick = (picture) => {
    setSelectedPicture(picture);
    executeFunctionWithId(picture._id);
  };

  const handleButtonClick = () => {
    executeAudioFunction(muteAudio);
    return !muteAudio;
  };

  const handleMouseLeave = () => {
    const iframe = document.querySelector("iframe");
    if (iframe) {
      const message = {
        type: "executeFunction",
        functionName: "handleMouseLeave",
      };
      iframe.contentWindow.postMessage(message, "*");
    }
  };

  const handleMouseEnter = () => {
    const iframe = document.querySelector("iframe");
    if (iframe) {
      const message = {
        type: "executeFunction",
        functionName: "handleMouseEnter",
      };
      iframe.contentWindow.postMessage(message, "*");
    }
  };

  return (
    <div className="gallery-container">
      <div className="content">
        <div className="iframe-container">
        {showWebView && <WebViewModal url={url} closeWebView={closeWebView}/>}
        <TeleporterOptions handleOptionTeleportSelect={handleOptionTeleportSelect} />
          <Instructions />
          {galleryData && galleryData.backgroundMusic && 
            <AudioPlayer data={galleryData.backgroundMusic} isMuted={muteAudio} />
          }
          <ElevatorOptions gallery={galleryData} setNewGallery={setNewGallery} galleriesData={galleriesData} />
          <BabylonIframe galleryData={galleryData} />
          { /*<ArrowControls onArrowClick={handleArrowClick} /> */}
        </div>
        <div
          className={`list-container ${selectedPicture ? "max-w-[45%]" : ""} md:max-w-[40%] lg:max-w-[40%]`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className={
              listVisible
                ? selectedPicture
                  ? "overflow-scroll w-full md:w-4/5 lg:w-4/5"
                  : "overflow-scroll w-full md:w-4/5 lg:w-full"
                : "hidden"
            }
          >
            {selectedPicture ? (
              <ProductDetail
                picture={selectedPicture}
                handleBack={() => setSelectedPicture(null)}
                mannequinButton={mannequinButton}
                handleWebView={handleWebView}
              />
            ) : (
              <ListProduts
                pictures={data?.pictures}
                handlePictureClick={handlePictureClick}
                listVisible={listVisible}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
              />
            )}
          </div>
          <Fullscreen />
          <ListActions
              listVisible={listVisible}
              setListVisible={setListVisible}
              muteAudio={muteAudio}
              setMuteAudio={setMuteAudio}
              handleButtonClick={handleButtonClick}
            />
        </div>
      </div>
    </div>
  );
}

function App() {
  const [isDoor, setIsDoor] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const [isViewFullscreen, setIsViewFullscreen] = useState({
    state: false,
    url: ""
  });

  const changeGallery = () => {
    setIsDoor(false);
  };

  const handleBabylonContentLoad = (event) => {
    if (event.data.type === "load") {
      const object = event.data.objectInfo.load;
      if (object === true) {
        setIsLoadingComplete(true);
        window.removeEventListener("message", handleBabylonContentLoad);
      }
    }
  };

  const handleViewFullscreen = (event) => {
    if (event.data.type === "view3d") {
      const receivedUrl = event.data.data.url;
      setIsViewFullscreen({
        state: true,
        url: receivedUrl
      });
    }
  };

  window.addEventListener("message", handleViewFullscreen);
  window.addEventListener("message", handleBabylonContentLoad);

  const queryClient = new QueryClient() 

  return (
    <DataContext.Provider value={handlePostMessage}>
      <MedusaProvider
        queryClientProviderProps={{ client: queryClient }}
        baseUrl="https://medusa.businessabc.net"
        customHeaders={{
          "Authorization" : "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoidXNyXzAxSFY2UkNZQlRNUUEyNTA0SlRRVFNWSkIyIiwiZG9tYWluIjoiYWRtaW4iLCJpYXQiOjE3MTk5MzE2NzcsImV4cCI6MTcyMDAxODA3N30.7auHvymeaqVlyDa3HjvVKPrgWYnFADnhqY9OUzwFeek"
        }}
      >
        <Viewer3d isViewFullscreen={isViewFullscreen} setIsViewFullscreen={setIsViewFullscreen} />
        {isDoor && <Door setDoor={setIsDoor} setIsLoading={setIsLoading} />}
        <div className="app-container">
          {isLoading && <Loader isLoading={isLoading} setIsLoading={setIsLoading} isLoadingComplete={isLoadingComplete} />}
          <Gallery changeGallery={changeGallery} />
        </div>
      </MedusaProvider>
    </DataContext.Provider>
  );

}

export default App;