// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.porta {
  position: absolute;
  display: block;
  height: 100vh;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  perspective: 1200px;
  z-index: 13;
}

.parte-esquerda,
.parte-direita {
  object-fit: contain;
  width: 50%;
  height: 100%;
  position: absolute;
  margin: 0;
  transition: transform 3s ease;
}

.parte-esquerda {
  background-color: #ffffff; /* Cor da parte esquerda */
  left: 0;
  transform-origin: left center;
}

.parte-direita {
  background-color: #ffffff; /* Cor da parte direita */
  right: 0;
  transform-origin: right center;
}

.aberta .parte-esquerda {
  transform: rotateY(-110deg);
}

.aberta .parte-direita {
  transform: rotateY(110deg);
}

.sideImage{
  width: 100%;
  height: 100%;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/door/assets/css/door.css"],"names":[],"mappings":"AACE;EAAA,kBAA6D;EAA7D,cAA6D;EAA7D,aAA6D;EAA7D,eAA6D;EAA7D,gBAA6D;EAC7D,WAAW;EACX,mBAAmB;EACnB;AAH6D;;AAM/D;;EAEE,mBAAmB;EACnB,UAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB,EAAE,0BAA0B;EACrD,OAAO;EACP,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB,EAAE,yBAAyB;EACpD,QAAQ;EACR,8BAA8B;AAChC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,SAAS;AACX","sourcesContent":[".porta {\n  @apply absolute overflow-hidden block cursor-pointer h-screen;\n  width: 100%;\n  perspective: 1200px;\n  z-index: 13;\n}\n\n.parte-esquerda,\n.parte-direita {\n  object-fit: contain;\n  width: 50%;\n  height: 100%;\n  position: absolute;\n  margin: 0;\n  transition: transform 3s ease;\n}\n\n.parte-esquerda {\n  background-color: #ffffff; /* Cor da parte esquerda */\n  left: 0;\n  transform-origin: left center;\n}\n\n.parte-direita {\n  background-color: #ffffff; /* Cor da parte direita */\n  right: 0;\n  transform-origin: right center;\n}\n\n.aberta .parte-esquerda {\n  transform: rotateY(-110deg);\n}\n\n.aberta .parte-direita {\n  transform: rotateY(110deg);\n}\n\n.sideImage{\n  width: 100%;\n  height: 100%;\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
