import React, { useState } from 'react';
import exit from './assets/images/exit.png';
import showInstructions from './assets/images/showInstructions.png';
import instructionsImage from "../../assets/imgs/instructions.png";
import mobileInstructionsImage from '../../assets/imgs/mobileInstructions.png';

import './assets/css/instructions.css';

const Instructions = () => {
  const [panelVisible, setPanelVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);

  const togglePanel = () => {
    setPanelVisible((prevVisible) => !prevVisible);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1200);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='instructions-container'>
      <div className='instructions-actions'>
        <div className='action-button cursor-pointer' onClick={togglePanel} >
          <img src={showInstructions} alt='Mostrar Instruções' />
        </div>
      </div>
      {panelVisible && (
        <div className='instructions-panel'>
          <div className="img-instructions-panel">
            <img src={isMobile ? mobileInstructionsImage : instructionsImage} alt='Instruções'/>
            <div className='absolute top-0 right-0 cursor-pointer' onClick={togglePanel}>
              <img src={exit} alt='Mostrar Instruções' />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { Instructions };
