import React, { useState } from "react";
import back from "../ProductDetail/Back dark.png"; // Certifique-se de que o caminho esteja correto

const Purchase = (props) => {
    const handleBack = props.handleBack;
    return (
        <div className="relative w-full, h-[100%] bg-[#b8b7b700] m-4 rounded-md">
        <img src={back} alt="Back" onClick={handleBack} />
        <div className="flex flex-col items-center w-full h-auto max-w-md p-4 mx-auto space-y-4 bg-[#5c5c5c] rounded-lg shadow-2xl">
        {/*<Image   
        src="./assets/card_exemple.png"
        width={500}
        height={500}
        alt="Picture of the author"
        />*/}
        <div className="w-full p-4 bg-[#343434] rounded-[10px]">
            <div className="flex justify-between">
            <div className="flex items-center space-x-2">
                <div className="w-8 h-8 bg-blue-600 rounded-[5px]" />
                <span className="text-white">VISA</span>
            </div>
            <div className="text-white">EXPIRY DATE</div>
            </div>
            <div className="flex justify-between mt-2">
            <div className="text-white">CARD NUMBER</div>
            <div className="text-white">12/2024</div>
            </div>
            <div className="mt-2 text-2xl font-bold text-white">4319 - 0215 - 5312 - 1289</div>
            <div className="flex justify-between mt-2">
            <div className="text-white">CARD HOLDER</div>
            <div className="px-2 py-1 text-sm text-white bg-green-600 rounded-[5px]">Active</div>
            </div>
            <div className="mt-2 text-white">DIVAN RAJ</div>
        </div>
        <div className="flex justify-center space-x-2">
            <div className="w-2 h-2 bg-gray-500 rounded-full" />
            <div className="w-2 h-2 bg-gray-500 rounded-full" />
            <div className="w-2 h-2 bg-gray-500 rounded-full" />
        </div>
        <div className="w-full space-y-4">
            <div className="space-y-2">
            <label htmlFor="cardholder-name" className="block text-sm font-medium text-white">
                Cardholder name
            </label>
            <input id="cardholder-name" type="text" className="w-full p-2 bg-[#5c5c5c3f] border-[#B8B7B7] border-2 rounded-[10px]" />
            </div>
            <div className="space-y-2">
            <label htmlFor="card-number" className="block text-sm font-medium text-white">
                Card number
            </label>
            <input id="card-number" type="text" className="w-full p-2 bg-[#5c5c5c3f] border-[#B8B7B7] border-2 rounded-[10px]" />
            </div>
            <div className="flex space-x-4">
            <div className="flex-1 space-y-2">
                <label htmlFor="expire-date" className="block text-sm font-medium text-white">
                Expire date
                </label>
                <div className="flex flex-row gap-2">

                    <input id="expire-date" type="text" className="w-full p-2 bg-[#5c5c5c3f] border-[#B8B7B7] border-2 rounded-[10px]" />
                    <input id="expire-date" type="text" className="w-full p-2 bg-[#5c5c5c3f] border-[#B8B7B7] border-2 rounded-[10px]" />
                    <input id="expire-date" type="text" className="w-full p-2 bg-[#5c5c5c3f] border-[#B8B7B7] border-2 rounded-[10px]" />
                </div>
            </div>

            <div className="flex-1 space-y-2">
                <label htmlFor="cvv" className="block text-sm font-medium text-white">
                CVV
                </label>
                <input id="cvv" type="text" className="w-full p-2 bg-[#5c5c5c3f] border-[#B8B7B7] border-2 rounded-[10px]" />
            </div>
            </div>
            <button className="w-[226px] h-[56px] text-white text-sm bg-[#2A844E] rounded-[40px] border-2 border-[#ffffff91]">Pay</button>
        </div>
        </div>
        </div>
    )
}

export { Purchase }