// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.instructions-container
{
  /* position: absolute;
  width: 100%;
  height: 100%; */
}
.instructions-actions {
  position: absolute;
  width: auto;
  height: auto;
  margin: 10px;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.instructions-panel {
  position: absolute;
  display: grid;
  width: 100%;
  height: 100%;
  margin: 10px;
  bottom: 0;
  left: 0;
  z-index: 20;
  justify-items: center;
  align-items: center;
}

.img-instructions-panel {
  position: relative;
  width: 80%;
  margin: 0;
}

.img-instructions-panel img {
  width: 100%;
  height: auto;
  display: block; /* Remover espaço extra abaixo da imagem */
}

.action-button-exit {
  position: absolute;
  top: 0;
  right: 0;
  margin: 22px;
  width: 50px; /* Definir a largura da imagem do botão "exit" */
  height: 50px; /* Definir a altura da imagem do botão "exit" */
  cursor: pointer; /* Transformar o cursor em uma mãozinha no hover */
}

.action-button-exit img {
  width: 100%;
  height: auto  ;
  display: block; /* Remover espaço extra ao redor da imagem */
}
`, "",{"version":3,"sources":["webpack://./src/components/instructions/assets/css/instructions.css"],"names":[],"mappings":";AACA;;EAEE;;iBAEe;AACjB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,SAAS;EACT,OAAO;EACP,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,SAAS;EACT,OAAO;EACP,WAAW;EACX,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc,EAAE,0CAA0C;AAC5D;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,YAAY;EACZ,WAAW,EAAE,gDAAgD;EAC7D,YAAY,EAAE,+CAA+C;EAC7D,eAAe,EAAE,kDAAkD;AACrE;;AAEA;EACE,WAAW;EACX,cAAc;EACd,cAAc,EAAE,4CAA4C;AAC9D","sourcesContent":["\n.instructions-container\n{\n  /* position: absolute;\n  width: 100%;\n  height: 100%; */\n}\n.instructions-actions {\n  position: absolute;\n  width: auto;\n  height: auto;\n  margin: 10px;\n  bottom: 0;\n  left: 0;\n  z-index: 10;\n}\n\n.instructions-panel {\n  position: absolute;\n  display: grid;\n  width: 100%;\n  height: 100%;\n  margin: 10px;\n  bottom: 0;\n  left: 0;\n  z-index: 20;\n  justify-items: center;\n  align-items: center;\n}\n\n.img-instructions-panel {\n  position: relative;\n  width: 80%;\n  margin: 0;\n}\n\n.img-instructions-panel img {\n  width: 100%;\n  height: auto;\n  display: block; /* Remover espaço extra abaixo da imagem */\n}\n\n.action-button-exit {\n  position: absolute;\n  top: 0;\n  right: 0;\n  margin: 22px;\n  width: 50px; /* Definir a largura da imagem do botão \"exit\" */\n  height: 50px; /* Definir a altura da imagem do botão \"exit\" */\n  cursor: pointer; /* Transformar o cursor em uma mãozinha no hover */\n}\n\n.action-button-exit img {\n  width: 100%;\n  height: auto  ;\n  display: block; /* Remover espaço extra ao redor da imagem */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
