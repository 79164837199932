export default function listProduct(obj) {
  return {
    _id: obj?.id,
    title: obj?.title,
    description: obj?.description,
    image: obj?.images[0]?.src,
    price: {
      value: obj.variants[0].price.amount,
      currencyCode: obj.variants[0].price.currencyCode ?? null,
    },
    variants: obj.variants?.map((p) => ({
      id: p.id,
      name: p.title,
      price: {
        value: p.price.amount,
        currencyCode: p.price.currencyCode ?? null,
      },
    })),
  }
}