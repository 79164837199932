import React from "react";
import background from "./Placeholder.png";

import "../../assets/style.css";
import "./assets/css/style.css";
import CardProduct from "../ProductDetail/CardProduct";

const ListProduts = (props) => {
  const { pictures, handlePictureClick } = props;
  return (
    <div className="p-5 grid md:grid-cols-1 lg:grid-cols-2 gap-3">
      {pictures &&
        pictures?.map((picture) => (
          <div
            key={picture._id}
            className="card"
            onClick={(e) => {
              e.stopPropagation();
              handlePictureClick(picture);
              window.scrollTo(0, 0);
            }}
          >
            <CardProduct picture={picture} background={background} />
          </div>
        ))}
    </div>
  );
};

export { ListProduts };
