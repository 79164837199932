import React, { useState, useEffect } from "react";
import "./assets/fullscreen.css";
import enterFullscreenImg from "./assets/images/expand.png"; // Certifique-se de que o caminho esteja correto
import exitFullscreenImg from "./assets/images/fullscreen.png"; // Certifique-se de que o caminho esteja correto

const Fullscreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const isCurrentlyFullscreen = () => {
    return document.fullscreenElement || 
           document.mozFullScreenElement || 
           document.webkitFullscreenElement || 
           document.msFullscreenElement;
  };

  const toggleFullscreen = () => {
    if (!isCurrentlyFullscreen()) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) { // Firefox
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
        document.documentElement.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { // IE/Edge
        document.msExitFullscreen();
      }
    }
  };

  const handleFullscreenChange = () => {
    setIsFullscreen(!!isCurrentlyFullscreen());
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange); // Firefox
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange); // Chrome, Safari and Opera
    document.addEventListener("msfullscreenchange", handleFullscreenChange); // IE/Edge

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener("mozfullscreenchange", handleFullscreenChange); // Firefox
      document.removeEventListener("webkitfullscreenchange", handleFullscreenChange); // Chrome, Safari and Opera
      document.removeEventListener("msfullscreenchange", handleFullscreenChange); // IE/Edge
    };
  }, []);

  return (
    <button className="fullscreen-button" onClick={toggleFullscreen}>
      <img
        src={isFullscreen ? exitFullscreenImg : enterFullscreenImg}
        alt={isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}
        className="fullscreen-icon"
      />
    </button>
  );
};

export { Fullscreen };
