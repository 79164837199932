import { X } from "lucide-react";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import map from "./assets/planta23DVertical.png"; // Certifique-se de que o caminho esteja correto
import marker from "./assets/marker.png"; // Certifique-se de que o caminho esteja correto

const TeleporterModalCanva = ({ onClose, handleOptionClick, teleporters = [] }) => {
  const canvasRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [image, setImage] = useState(new Image());
  const [markerImage, setMarkerImage] = useState(new Image());
  useLayoutEffect(() => {
    const updateSize = () => {
      const newWidth = window.innerWidth * 0.8; // Set canvas width to 80% of the window width
      setCanvasWidth(newWidth);
    };

    window.addEventListener('resize', updateSize);
    updateSize(); // Initialize canvas width

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
  
    image.src = map;
    markerImage.src = marker;
    image.onload = () => {
      setImageWidth(image.naturalWidth);
      setImageHeight(image.naturalHeight);
      canvas.height = image.naturalHeight;
      setImageLoaded(true);
    };
  }, [image, markerImage]); // Assegure-se de que a imagem do marker está carregada corretamente
  

  useEffect(() => {
    console.log(teleporters); // Verifique os dados
    const ctx = canvasRef.current.getContext('2d');
    if (imageLoaded) {
      ctx.clearRect(0, 0, canvasWidth, imageHeight);
      ctx.drawImage(image, (canvasWidth - imageWidth) / 2, 0); // Center image on the canvas
      drawMarkers(ctx);
    }
  
    const canvas = canvasRef.current;
    canvas.addEventListener('mousemove', (e) => handleMouseMove(e, ctx));
    canvas.addEventListener('click', (e) => handleClick(e, ctx));
  
    return () => {
      canvas.removeEventListener('mousemove', handleMouseMove);
      canvas.removeEventListener('click', handleClick);
    };
  }, [imageLoaded, canvasWidth, teleporters]); // Verifique os teleporters
  
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
  
    image.src = map;
    markerImage.src = marker;
  
    image.onload = () => {
      setImageWidth(image.naturalWidth);
      setImageHeight(image.naturalHeight);
      canvas.height = image.naturalHeight; // Define a altura do canvas com base na imagem
      canvas.width = canvasWidth; // Define a largura do canvas (com base em 80% da largura da janela)
      setImageLoaded(true);
    };
  }, [canvasWidth]);
  

  const drawMarkers = (ctx) => {
    if (!teleporters || teleporters.length === 0) return;
  
    teleporters.forEach((marker) => {
      if (marker.makerTransform && marker.makerTransform.position) {
        const xOffset = (canvasWidth - imageWidth) / 2; // Centraliza a imagem no canvas
        const x = xOffset + (marker.makerTransform.position.x / 100) * imageWidth; // Escala x corretamente
        const y = (marker.makerTransform.position.y / 100) * imageHeight; // Escala y corretamente
        ctx.drawImage(markerImage, x - 15, y - 15, 30, 30); // Desenha o marcador no canvas
        drawText(ctx, marker.label, x, y, marker.textOrientation); // Desenha o texto associado ao marcador
      }
    });
  };
  

  const drawText = (ctx, text, x, y, orientation) => {
    const padding = 10; // Define o espaço entre o texto e o marcador
    let textX = x; // Posição x inicial
    let textY = y; // Posição y inicial

    ctx.textBaseline = 'middle'; // Centraliza o texto verticalmente em relação ao marcador
    ctx.textAlign = 'center'; // Centraliza o texto horizontalmente em relação ao marcador

    switch (orientation) {
      case 'top':
        textY -= (15 + padding); // Ajusta posição y para acima
        break;
      case 'bottom':
        textY += (15 + padding); // Ajusta posição y para abaixo
        break;
      case 'left':
        textX -= (15 + padding); // Ajusta posição x para esquerda
        ctx.textAlign = 'right'; // Alinha o texto à direita
        break;
      case 'right':
        textX += (15 + padding); // Ajusta posição x para direita
        ctx.textAlign = 'left'; // Alinha o texto à esquerda
        break;
      default:
        textY -= (15 + padding); // Default é posicionar acima
    }

    ctx.fillStyle = "white"; // Cor de preenchimento do texto
    ctx.strokeStyle = "black"; // Cor do contorno do texto
    ctx.lineWidth = 1; // Espessura do contorno do texto
    ctx.font = "bold 12px Arial"; // Estilo do texto

    ctx.strokeText(text, textX, textY); // Desenha o contorno do texto
    ctx.fillText(text, textX, textY); // Desenha o texto preenchido
  };

  const handleMouseMove = (e, ctx) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    ctx.clearRect(0, 0, canvasWidth, imageHeight);
    ctx.drawImage(image, (canvasWidth - imageWidth) / 2, 0, imageWidth, imageHeight);
    drawMarkers(ctx);

    teleporters.forEach((marker) => {
      if (marker.makerTransform && marker.makerTransform.position) {
        const xOffset = (canvasWidth - imageWidth) / 2;
        const markerX = xOffset + (marker.makerTransform.position.x / 100) * imageWidth;
        const markerY = (marker.makerTransform.position.y / 100) * imageHeight;
        if (x >= markerX - 15 && x <= markerX + 15 && y >= markerY - 15 && y <= markerY + 15) {
          ctx.drawImage(markerImage, markerX - 18, markerY - 18, 36, 36);
        } else {
          ctx.drawImage(markerImage, markerX - 15, markerY - 15, 30, 30);
        }
      }
    });
  };

  const handleClick = (e, ctx) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    teleporters.forEach((marker) => {
      if (marker.makerTransform && marker.makerTransform.position) {
        const xOffset = (canvasWidth - imageWidth) / 2;
        const markerX = xOffset + (marker.makerTransform.position.x / 100) * imageWidth;
        const markerY = (marker.makerTransform.position.y / 100) * imageHeight;
        if (x >= markerX - 15 && x <= markerX + 15 && y >= markerY - 15 && y <= markerY + 15) {
          handleOptionClick(marker); // Corrigido para passar o marcador diretamente
        }
      }
    });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-20 m-10">
      <div className="relative w-auto h-auto bg-[rgba(255,255,255,0.1)] text-white backdrop-blur-[30px] rounded-xl shadow-2xl">
        <button
          className="absolute z-10 top-2 right-2 p-1 text-white hover:bg-white/20 rounded-full shadow-md"
          onClick={onClose}
        >
          <X className="h-6 w-6" />
          <span className="sr-only">Close</span>
        </button>
        <div className="absolute text-center py-4">
          <h2 className="text-2xl font-semibold drop-shadow-md text-left m-4 ml-8">Where do you want to go?</h2>
        </div>
        <canvas ref={canvasRef} width={canvasWidth} height={imageHeight} className="max-w-full max-h-full"></canvas>
      </div>
    </div>
  );
};

export { TeleporterModalCanva };
