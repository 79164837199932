export default function listProduct(obj) {
  return {
    _id: obj?.id,
    title: obj?.title,
    description: obj?.description,
    image: obj?.thumbnail,
    object3d: obj.object3d,
    price: {
      value: "",
      currencyCode: "",
    },
    variants: obj.variants?.map((v) => ({
      id: v.id,
      name: v.title,
      price: v.prices?.map((p) => ({
        value: p.amount,
        currencyCode: p.currency_code ?? null,
      })),
    })),
    metadata: obj?.metadata
  }
}