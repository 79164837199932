import client from "./auth";
import listProduct from "./functions/listProduct";

export default class ShopifyRequest{
  fetchProducts() {
    return client.product.fetchAll().then((products) => {
      //console.log("LIST - ", products)
      return products.map(p => listProduct(p))
    });
  }
}