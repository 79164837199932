import React, { useState, useEffect } from "react";
import locationIcon from "./assets/location.png";
import { TeleporterModalCanva } from "./teleporterModalCanva.js";
import "./assets/teleporterOption.css"; 

const TeleporterOptions = ({ handleOptionTeleportSelect }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [teleporters, setTeleporters] = useState([]);
  const [animationActive, setAnimationActive] = useState(true);  
  const [showEffect, setShowEffect] = useState(true);  // Novo estado para controlar a visibilidade do efeito

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    setAnimationActive(true);  // Ativa a animação ao clicar
    setTimeout(() => {
      setAnimationActive(false);  // Desativa a animação após 1 segundo
      setShowEffect(false);       // Remove o efeito visual após o clique
    }, 1000);
  };

  const handleCloseModal = () => {
    setIsExpanded(false);
  };

  const handleOptionClick = (option) => {
    if (handleOptionTeleportSelect) {
      handleOptionTeleportSelect(option);
      handleCloseModal();
    }
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === "teleportersData") {
        setTeleporters(event.data.data);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);
  
  if(teleporters.length <= 0) return null;

  return (
    <div>
      <div className="fixed z-10 bottom-12 mb-2 left-0 flex justify-center">
        <div className="relative">
          {showEffect && (
            <div className={`circle-outline ${animationActive ? 'loop-animation' : ''}`} />
          )}
          <img
            alt="Location"
            src={locationIcon}
            className="relative w-[53px] h-[53px] ml-3 cursor-pointer"
            onClick={toggleExpand}
          />
        </div>
      </div>
      {isExpanded && (
        <TeleporterModalCanva
        onClose={handleCloseModal}
        handleOptionClick={handleOptionClick}
        teleporters={teleporters}
        />
      )}
    </div>
  );
};

export { TeleporterOptions };
