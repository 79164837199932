import React, { useState, useEffect } from "react";
import "./assets/style.css";
import btnExit from "./assets/exit.png";

const Viewer3d = ({ isViewFullscreen, setIsViewFullscreen }) => {
  const [visibility, setVisibility] = useState(false);
  const [url, setUrl] = useState("");

  const baseUrl = process.env.REACT_APP_VIEWER;

  useEffect(() => {
    if (isViewFullscreen.state) {
      setVisibility(true);
      setUrl(isViewFullscreen.url);
    }
  }, [isViewFullscreen]); // Adicionamos `isViewFullscreen` como dependência do efeito

  const handleExitClick = () => {
    setVisibility(false);
  };

  return (
    <div className={`viewer3d ${visibility ? "block" : "hidden"}`}>
      {visibility && (
        <div id="360viewer" className="relative w-full h-full bg-white z-10">
        <div className="absolute top-0 left-0 m-2 z-20" onClick={handleExitClick}>
          <img src={btnExit} alt="Exit Fullscreen" className="cursor-pointer"/>
        </div>
          <iframe
            className="iframeViewer absolute top-0 left-0 w-full h-full z-10 border-none"
            id="iframeViewer"
            title="Babylon Viewer"
            src={`${baseUrl}?url=${encodeURIComponent(url)}`}
            frameBorder="0"
          ></iframe>
        </div>
      )}
    </div>
  );
  
};

export { Viewer3d };
