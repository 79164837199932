import React from "react";
import heartIcon from "../../assets/imgs/heart.png";
import { Button360 } from "./button360.js";

export default function CardProduct({ picture, background, show360Button = false, color }) {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="image-placeholder relative"
      >
        <div
          className="product-mesh w-full h-full"
          style={{
            filter: `hue-rotate(${color})`, // Apply color filter to the product mesh
          }}
        >
          <img src={picture.url} alt={picture.title} className="w-full h-full object-cover" />
        </div>
        {show360Button && <Button360 id={picture._id} />}
      </div>
      <div className="card-content">
        <div className="w-full">
          <div className="flex justify-between items-center">
            <p className="text-white text-xl font-bold leading-6 my-2 titleElippes">
              {picture.title}
            </p>
            <div className="h-6 w-6 flex-shrink-0">
              <img alt="Heart" src={heartIcon} className='cursor-pointer'/>
            </div>
          </div>
          {/* {picture?.price?.length !== 0 && (
            <p className="price">
              Price{" "}
              {formatCurrency(
                picture?.price.value,
                picture?.price.currencyCode
              )}
            </p>
          )} */}
        </div>
        <hr className="divider" />
        {/* <div className="author-container">
          <div className="author-container-title">
            <p className="author-title">Shipping terms</p>
          </div>
          <div className="author-container-name">
            <p className="author-name">{picture.author}</p>
          </div>
        </div> */}
        <div className="description-container">
          <p className="description-title">Description</p>
          <p className="description">{picture.desc}</p>
        </div>
      </div>
    </div>
  );
}