// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listVisibleControl {
    display: block;
    position: relative;
    top: 2%;
    width: auto;
    height: 100%;
    margin: 10px;
    margin-top: 23px;
    /* z-index: 10; */
  }

  .buttons-actions
  {
    display: grid;
    justify-items: end;
    width: 100%;
    height: 50px;
  }

  .exit-button{
    position: relative;
    top: 86%;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/controls/assets/css/listActions.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,OAAO;IACP,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;;IAEE,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,QAAQ;EACV","sourcesContent":[".listVisibleControl {\n    display: block;\n    position: relative;\n    top: 2%;\n    width: auto;\n    height: 100%;\n    margin: 10px;\n    margin-top: 23px;\n    /* z-index: 10; */\n  }\n\n  .buttons-actions\n  {\n    display: grid;\n    justify-items: end;\n    width: 100%;\n    height: 50px;\n  }\n\n  .exit-button{\n    position: relative;\n    top: 86%;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
