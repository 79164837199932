import React, { useState, useEffect, useRef } from "react";
import iconUpFloor from "./assets/images/elevatorIconUp.png";
import iconDownFloor from "./assets/images/elevatorIconDown.png";
import iconCurrentFloor from "./assets/images/elevatorIconCurrent.png";
import iconExit from "./assets/images/exit.png";

import './style.css';

// Componente de botão personalizado
const ElevatorButton = ({ option, gallery, handleButtonClick }) => {
  // Verifique se o botão atual é o botão do "Current Gallery"
  const isCurrentGallery = option.route === gallery.route;
  const isCurrentFloor = gallery.floor;

  // Crie uma classe base para o botão
  const buttonClass = `text-left elevator-button w-full ${
    isCurrentGallery ? "currentFloor" : ""
  }`;

  return (
<button
  key={option.route}
  disabled={isCurrentGallery} // Desative o botão se for o botão do "Current Gallery"
  onClick={() => handleButtonClick(option)}
  className={buttonClass}
>
  <div className="text-left elevator-button-content">
    <img
      src={
        isCurrentGallery
          ? iconCurrentFloor
          : option.floor > isCurrentFloor
          ? iconUpFloor
          : iconDownFloor
      }
      alt="Floor Icon"
      className="elevator-image"
    />
    <div className="elevator-text">
      <h3 className="floor-title">{option.option}</h3>
      <p className="floor-desc">{option.description}</p>
    </div>
  </div>
</button>

  );
};


const ElevatorOptions = (props) => {
  
  const [selectedOption, setSelectedOption] = useState(null);
  const [elevatorUI, SetElevatorUI] = useState(false);

  const selectedOptionRef = useRef(null);

  var {gallery, setNewGallery, galleriesData } = props;
  

  const handleOptionClick = (option) => {
    selectedOptionRef.current = option;
    var floor = option.floor > gallery.floor ? "Up" : "Down";
    localStorage.setItem("floorDirection", floor);
    galleryFloor(floor);
    SetElevatorUI(false);
    setSelectedOption(option.route);
    elevatorAction(option.route);
    //setNewGallery(option);
  };

  function ChangeGallery(){
    const selectedOption = selectedOptionRef.current;
    if(selectedOption != null){
      setNewGallery(selectedOption);
    }
  }

  const createElevatorButtons = () => {
    return galleriesData
      .filter((option) => option.elevator) // Filtra as opções onde elevator é verdadeiro
      .map((option) => (
        <ElevatorButton
          key={option.route}
          option={option}
          gallery={gallery}
          handleButtonClick={handleOptionClick}
        />
      ));
  };

  useEffect(() => {
    const handleElevator = (event) => {
      if (event.data.type === "elevator") {
        const object = event.data.objectInfo.status;
        if (object === true) {
          SetElevatorUI(true);
        } else {
          SetElevatorUI(false);
        }
      }
      if(event.data.type === "SetNewGallery"){
        ChangeGallery();
      }
    };
    var floorDirection = localStorage.getItem("floorDirection");
    galleryFloor(floorDirection);

    window.addEventListener("message", handleElevator);

    return () => {
      window.removeEventListener("message", handleElevator);
    };
  }, []);

  const elevatorAction = (option) => {
    const iframe = document.querySelector("iframe");
    if (iframe) {
      const message = {
        type: "executeFunction",
        functionName: "moveElevator",
        arguments: [option],
      };
      iframe.contentWindow.postMessage(message, "*");
    }
  };

  const galleryFloor = (floor) => {
    
    const iframe = document.querySelector("iframe");
    if (iframe) {
      const message = {
        type: "executeFunction",
        functionName: "galleryFloor",
        arguments: [floor],
      };

      iframe.contentWindow.postMessage(message, "*");
    }
  };

  const handleMouseEnter = () => {
    if (elevatorUI) {
      SetElevatorUI(true);
    }
  };

  const handleMouseLeave = () => {
    //SetElevatorUI(false);
  };
  const handleExitUI = () => {
    SetElevatorUI(false);
  };

  if (elevatorUI) {
    return (
      <div
        className="elevator-options"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        
        <div className="elevatorTitleContainer">
        <div className="elevatorTitle">Elevator</div>
        <div className="iconExit" >
          <img
            className="w-10"
            src={iconExit}
            alt="Exit Elevator"
            onClick={handleExitUI}
          />
        </div>
      </div>

      <div className="elevator-options-buttons">
        {createElevatorButtons()}
      </div>
      </div>
    );
  } else {
    return null;
  }
};

export { ElevatorOptions };
