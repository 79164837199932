import clientMedusa from "./medusa/auth.js";
import { provider } from "./request.js";

const APIRequest = {
  async fetchStoreTemplate() {
    const response = await clientMedusa.admin.store.retrieve();
    
    return response.store.storeTemplate.template_id;
  },

  async fetchStores() {
    const response = await clientMedusa.admin.custom.get(`/admin/stores`);
    return response.listStore;
  },

  async fetchProducts() {
    const response = await provider("medusa").fetchProducts();
    
    return response;
  },

  async fetchVideos() {
    const response = await clientMedusa.admin.custom.get(`/admin/store_gallery?type=video`);
    return response.list;
  }
};

export default APIRequest;
