import React, { useState } from "react";
import { Button } from "react-bootstrap";
import background from "./Placeholder.png";
import mannequin from "./shirt-solid-white.png";
import buy from "./grocery-store.png";
import buy_black from "./grocery-store-black.png";
import mannequin_black from "./shirt-solid.png";
import back from "./Back dark.png"; // Certifique-se de que o caminho esteja correto
import "../../assets/style.css";
import CardProduct from "./CardProduct";
import { Purchase } from "../purchase/purchase.js"; // Importe o componente Purchase
import { X } from "lucide-react";

const isInternalUrl = (url) => {
  return url === "local" || !url; // Considera "local" ou vazio como inválido
};

const ProductDetail = (props) => {
  const { picture, handleBack, mannequinButton, handleWebView } = props;
  const [color, setColor] = useState(0);
  const [showPurchase, setShowPurchase] = useState(false); // Estado para controlar a exibição do componente Purchase
  
  console.log("product detail");

  const handleRedirect = () => {
    if (!isInternalUrl(picture.url_action)) {
      try {
        handleWebView(picture.url_action);
      } catch (error) {
        window.open(picture.url_action, "_blank");
      }
    }
  };
  
  const backPurchase = () => {
    setShowPurchase(false);
  };

  const ShowInMannequin = () => {
    const iframe = document.querySelector("iframe");
    if (iframe) {
      const message = {
        type: "executeFunction",
        functionName: "ShowInMannequin",
      };
      iframe.contentWindow.postMessage(message, "*");
    }
  };

  const ChangeMannequinButtonSource = (isHover) => {
    const mannequinButtonImg = document.getElementById("mannequinButtonImg");
    const mannequinButton = document.getElementById("mannequinButton");

    if (isHover) {
      mannequinButtonImg.src = mannequin_black;
      mannequinButton.style.width = "44px";
    } else {
      mannequinButtonImg.src = mannequin;
      mannequinButtonImg.style.margin = "0px 0px 0px 0px";
      mannequinButton.style.width = "44px";
    }
  };

  const ChangeBuyButtonSource = (isHover) => {
    const buyButtonImg = document.getElementById("buyButtonImg");
    const buyButton = document.getElementById("buyButton");

    if (isHover) {
      buyButtonImg.src = buy_black;
      buyButton.style.width = "44px";
    } else {
      buyButtonImg.src = buy;
      buyButtonImg.style.margin = "0px 0px 0px 0px";
      buyButton.style.width = "44px";
    }
  };

  const changeColor = () => {
    const newColor = (color + 45) % 360; // Change the hue rotation value
    setColor(newColor);
  };

  if (showPurchase) {
    return <Purchase handleBack={backPurchase} />;
  }

  return (
    <div className="picture-details">
      <div className="product-content">
        <img src={back} alt="Back" onClick={handleBack} className="cursor-pointer" />
        <CardProduct picture={picture} background={background} show360Button={true} color={`${color}deg`} />
        <div className="flex">
          <div className="px-3">
            {/* Verifica se a URL está disponível ou não */}
            {!isInternalUrl(picture.url_action) ? (
              <Button
                id="buyButton"
                style={{ width: 44 + "px" }}
                className="btnAction"
                onClick={handleRedirect} // Redireciona se a URL for válida
                onMouseOver={() => ChangeBuyButtonSource(true)}
                onMouseLeave={() => ChangeBuyButtonSource(false)}
              >
                <img id="buyButtonImg" src={buy} style={{ height: 17 + "px" }} />
              </Button>
            ) : (
              <Button
                id="notForSaleButton"
                className="btnActionNotForSale cursor-not-allowed"
                disabled // Botão desativado
              >
                Not for Sale
              </Button>
            )}
          </div>
          {mannequinButton && (
            <div className="px-3">
              <Button
                id="mannequinButton"
                style={{ width: 44 + "px" }}
                className="btnAction"
                onClick={ShowInMannequin}
                onMouseOver={() => ChangeMannequinButtonSource(true)}
                onMouseLeave={() => ChangeMannequinButtonSource(false)}
              >
                <img id="mannequinButtonImg" src={mannequin} style={{ height: 17 + "px" }} />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { ProductDetail };
