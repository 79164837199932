import React from 'react';
import showUI from './assets/images/show menu Dark.png';  // Certifique-se de que o caminho esteja correto
import hideUI from './assets/images/hide menu Dark.png';  // Certifique-se de que o caminho esteja correto
import mute from './assets/images/muted sound.png';  // Certifique-se de que o caminho esteja correto
import unmute from './assets/images/unmute sound.png';  // Certifique-se de que o caminho esteja correto
import './assets/css/listActions.css';  // Certifique-se de que o caminho esteja correto

const ListActions = ({ listVisible, setListVisible, muteAudio, setMuteAudio, handleButtonClick }) => {
  return (
    <div className='listVisibleControl top-0 h-min'>
      <div className="buttons-actions list-button" onClick={() => setListVisible((prevVisible) => !prevVisible)}>
        <img src={listVisible ? hideUI : showUI} alt={listVisible ? "Min Icon" : "Max Icon"} className='cursor-pointer'/>
      </div>
      <div className="buttons-actions list-button" onClick={() => {
        const newMuteState = !muteAudio;
        setMuteAudio(newMuteState);
        handleButtonClick(newMuteState); // Atualiza o estado no componente pai
      }}>
        <img src={muteAudio ? mute : unmute} alt={muteAudio ? "Mute Audio" : "Unmute Audio"} className='cursor-pointer'/>
      </div>
    </div>
  );
}

export { ListActions };
