// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.viewer3d {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .iframeViewer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    margin: 0;
    padding: 0;
  }
  
  .viewer3d button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
  }

  .viewer3d .exitButton {
    position: absolute;
    top: 10px; /* Ajuste a posição superior conforme necessário */
    left: 10px; /* Ajuste a posição esquerda conforme necessário */
    z-index: 20;
  }

  
  `, "",{"version":3,"sources":["webpack://./src/components/viewer3d/assets/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;IACd,WAAW;IACX,YAAY;IACZ,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,WAAW;IACX,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,SAAS,EAAE,kDAAkD;IAC7D,UAAU,EAAE,kDAAkD;IAC9D,WAAW;EACb","sourcesContent":[".viewer3d {\n    position: relative;\n    display: block;\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    padding: 0;\n  }\n  \n  .iframeViewer {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 10;\n    margin: 0;\n    padding: 0;\n  }\n  \n  .viewer3d button {\n    position: absolute;\n    top: 0;\n    left: 0;\n    z-index: 20;\n  }\n\n  .viewer3d .exitButton {\n    position: absolute;\n    top: 10px; /* Ajuste a posição superior conforme necessário */\n    left: 10px; /* Ajuste a posição esquerda conforme necessário */\n    z-index: 20;\n  }\n\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
