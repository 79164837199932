import React, { useEffect, useState, useRef } from "react";
import "./assets/css/loader.css";
import loaderBackground from "./assets/images/loaderBackground.jpg";
import instructionsImage from "../../assets/imgs/instructions.png";
import mobileInstructionsImage from "../../assets/imgs/mobileInstructions.png";
import introtest from "../instructions/assets/videos/introtest.webm";

const Loader = (props) => {
  const { isLoading, setIsLoading, isLoadingComplete } = props;
  const [progress, setProgress] = useState(0);
  const [fadeOut, setFadeOut] = useState(false); 
  const [stopProgressAt, setStopProgressAt] = useState(400); 
  const [isMobile, setIsMobile] = useState(false); 
  const videoRef = useRef(null);

  useEffect(() => {
    const checkIsMobile = () => window.innerWidth <= 1200;
    setIsMobile(checkIsMobile());

    const handleResize = () => setIsMobile(checkIsMobile());
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isLoading) return;

    const interval = setInterval(() => {
      if (isLoadingComplete) {
        if (progress < 500) {
          setProgress(progress + 2);
        } else {
          setFadeOut(true);
          clearInterval(interval);
        }
      } else {
        if (progress < 500 && progress < stopProgressAt) {
          setProgress(progress + 1);
        }
      }
    }, 5);

    return () => clearInterval(interval);
  }, [progress, isLoadingComplete, isLoading]);

  useEffect(() => {
    if(fadeOut) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }
  }, [fadeOut]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener("ended", handleVideoEnd);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("ended", handleVideoEnd);
      }
    };
  }, []);

  const handleVideoEnd = () => {
    setFadeOut(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  const containerClass = `loader-container${fadeOut ? " fade-out" : ""}`;

  const wisdomiaPath = "/wisdomia";
  const currentPath = window.location.pathname;
  const isWisdomiaPath = currentPath === wisdomiaPath;
  
  return (
    <div className={containerClass}>
      {isWisdomiaPath && !isMobile ? (
        <video
          ref={videoRef}
          className="intro-video"
          autoPlay
          muted
          onEnded={handleVideoEnd}
        >
          <source src={introtest} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <>
          <img className="imgBackground" src={loaderBackground} alt="Background" />
          <div className="z-10 flex flex-col justify-center items-center gap-10 w-full h-full">
            <div className="loader-title-description">
              <h1></h1>
              <p></p>
            </div>
            <div className="loader-instructions">
              <img className="img-instructions" src={isMobile ? mobileInstructionsImage : instructionsImage} alt="Instruções de Navegação" />
            </div>
            <div className="loadContainer">
              <p>Loading</p>
              <progress className="loadProgress" value={progress} max="500" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export { Loader };
