// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle-shadow {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.circle-outline {
  position: absolute;
  top: 49%;
  left: 59%;
  transform: translate(-50%, -50%); /* Centraliza o círculo ao redor da imagem */
  width: 45px; /* Ajusta para garantir o tamanho levemente maior que o ícone */
  height: 45px;
  border-radius: 50%;
  border: 2px solid rgba(0, 174, 239, 0.75); /* Cor azulada */
  box-shadow: inset 0 0 20px rgba(0, 174, 239, 0.35), 0 0 20px rgba(0, 174, 239, 0.2); /* Sombra azulada */
  pointer-events: none; /* O círculo não interfere na interação */
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
}

.loop-animation {
  animation: pulseAnimation 2s infinite ease-in-out; /* Loop infinito de animação */
}

@keyframes pulseAnimation {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/teleporter/assets/teleporterOption.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC,EAAE,4CAA4C;EAC9E,WAAW,EAAE,+DAA+D;EAC5E,YAAY;EACZ,kBAAkB;EAClB,yCAAyC,EAAE,gBAAgB;EAC3D,mFAAmF,EAAE,mBAAmB;EACxG,oBAAoB,EAAE,yCAAyC;EAC/D,qDAAqD;AACvD;;AAEA;EACE,iDAAiD,EAAE,8BAA8B;AACnF;;AAEA;EACE;IACE,yCAAyC;IACzC,UAAU;EACZ;EACA;IACE,2CAA2C;IAC3C,YAAY;EACd;EACA;IACE,yCAAyC;IACzC,UAAU;EACZ;AACF","sourcesContent":[".circle-shadow {\n  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);\n  border-radius: 50%;\n}\n\n.circle-outline {\n  position: absolute;\n  top: 49%;\n  left: 59%;\n  transform: translate(-50%, -50%); /* Centraliza o círculo ao redor da imagem */\n  width: 45px; /* Ajusta para garantir o tamanho levemente maior que o ícone */\n  height: 45px;\n  border-radius: 50%;\n  border: 2px solid rgba(0, 174, 239, 0.75); /* Cor azulada */\n  box-shadow: inset 0 0 20px rgba(0, 174, 239, 0.35), 0 0 20px rgba(0, 174, 239, 0.2); /* Sombra azulada */\n  pointer-events: none; /* O círculo não interfere na interação */\n  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);\n}\n\n.loop-animation {\n  animation: pulseAnimation 2s infinite ease-in-out; /* Loop infinito de animação */\n}\n\n@keyframes pulseAnimation {\n  0% {\n    transform: translate(-50%, -50%) scale(1);\n    opacity: 1;\n  }\n  50% {\n    transform: translate(-50%, -50%) scale(1.2);\n    opacity: 0.7;\n  }\n  100% {\n    transform: translate(-50%, -50%) scale(1);\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
