import React, { useEffect, useRef } from "react";
import "./assets/style.css";
import { X } from "lucide-react";

const WebViewModal = ({ url, closeWebView }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;

    // Função de fallback caso o iframe seja bloqueado
    const handleIframeError = () => {
      window.open(url, "_blank"); // Abre a URL em uma nova aba
      closeWebView(); // Fecha o WebViewModal
    };

    // Detecta quando a página dentro do iframe tenta carregar
    iframe.onload = () => {
      // Verifica se o conteúdo foi bloqueado por política de segurança
      try {
        const testAccess = iframe.contentWindow.location.href; // Tenta acessar o iframe
      } catch (error) {
        handleIframeError(); // Se houver erro, aplica o fallback
      }
    };

    // Verifica se há um erro logo na criação do iframe
    iframe.onerror = handleIframeError;

  }, [url, closeWebView]);

  return (
    <div className="webview-container">
      <div className="webview-content">
        {/* Botão de fechar o WebView */}
        <button
          className="absolute z-10 top-2 right-2 p-1 text-white hover:bg-white/20 rounded-full shadow-md"
          onClick={closeWebView}
        >
          <X className="h-6 w-6" />
          <span className="sr-only">Close</span>
        </button>

        {/* Iframe para carregar a URL */}
        <iframe
          ref={iframeRef}
          src={url}
          title="WebView"
          className="webview-frame"
        />
      </div>
    </div>
  );
};

export { WebViewModal };
